<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit invoice format') + ` id: ${form.id}` : $t('Create invoice format') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :horizontal="{ input: 'col-sm-3' }" name="month_id" label="Month" v-model="form.month_id" :options="available_months" :isValid="isValid('month_id')" :errors="getErrors('month_id')" />
              <AMultiSelect name="invoice_format_combination_id" label="Combination" v-model="form.invoice_format_combination_id" :options="lists.invoice_format_combinations" :isValid="isValid('invoice_format_combination_id')" :errors="getErrors('invoice_format_combination_id')" @select="value => onChangeCombination(value.id)" />

              <AMultiSelect v-if="form.id" :disabled="true" name="output_id" label="Output" v-model="form.output_id" :options="lists.output_types" :isValid="isValid('output_id')" :errors="getErrors('output_id')" />

              <ACheckBox v-if="!is_mixed" label="Payment receipt" placeholder="Generar un recibo de caja por cada pago ingresado." v-model="form.payment_receipt" />
              <ACheckBox label="Mixed reference" placeholder="Se activa para mostrar en la factura la torre y el código del inmueble (ej. 04-0504)." v-model="form.show_mixed" />
            </ACard>

            <ACard title="Invoice formats">
              <AMultiSelect v-if="form.id" name="invoice_format_marker_id" label="Invoice format" v-model="form.invoice_format_marker_id" :options="lists.invoice_format_markers" :isValid="isValid('invoice_format_marker_id')" :errors="getErrors('invoice_format_marker_id')" />

              <AMultiSelect v-if="!form.id" name="printed_invoice_format_marker_id" label="Printed invoice" v-model="form.printed_invoice_format_marker_id" :prependId="true" activeOnly="status_id" :options="lists.invoice_format_markers" :isValid="isValid('printed_invoice_format_marker_id')" :errors="getErrors('printed_invoice_format_marker_id')" />
              <AMultiSelect v-if="!form.id" name="digital_invoice_format_marker_id" label="Digital invoice" v-model="form.digital_invoice_format_marker_id" :prependId="true" activeOnly="status_id" :options="lists.invoice_format_markers" :isValid="isValid('digital_invoice_format_marker_id')" :errors="getErrors('digital_invoice_format_marker_id')" />
              <hr v-if="!form.id" />

              <AMultiSelect v-if="is_qr" name="invoice_format_qr_id" label="QR code type" v-model="form.invoice_format_qr_id" :options="lists.invoice_format_qrs" :isValid="isValid('invoice_format_qr_id')" :errors="getErrors('invoice_format_qr_id')" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'
import parametersMixin from '@/app/views/_mixins/parameters-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'InvoiceFormatForm',
  mixins: [formMixin, parametersMixin],
  computed: {
    is_qr() {
      let is_qr = false
      if (this.form.id) {
        //if (this.findInvoiceFormatMarker(this.form.invoice_format_marker_id).name.indexOf('QR') >= 0) is_qr = true
        if (this.form.invoice_format_marker_id && this.form.invoice_format_marker_id.indexOf('QR') >= 0) is_qr = true
      } else {
        //if (this.findInvoiceFormatMarker(this.form.printed_invoice_format_marker_id).name.indexOf('QR') >= 0) is_qr = true
        if (this.form.printed_invoice_format_marker_id && this.form.printed_invoice_format_marker_id.indexOf('QR') >= 0) is_qr = true
        //if (this.findInvoiceFormatMarker(this.form.digital_invoice_format_marker_id).name.indexOf('QR') >= 0) is_qr = true
        if (this.form.digital_invoice_format_marker_id && this.form.digital_invoice_format_marker_id.indexOf('QR') >= 0) is_qr = true
      }
      return is_qr
    },
    // TODO: verify this!
    is_receipt() {
      if (this.VALID_RECEIPT.indexOf(this.form.invoice_format_combination_id) >= 0) return true
      return false
    },
    is_mixed() {
      return this.form.invoice_format_combination_id === this.COMBINATION_MIXED
    }
  },

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper
      VALID_RECEIPT: ['FC', 'SM'],
      COMBINATION_MIXED: 'MX',

      // DATA
      form: { id: 0 },

      // Extra
      lists: {
        months: [],
        invoice_format_qrs: [],
        invoice_format_markers: [],
        invoice_format_combinations: []
      },

      // Helpers
      alerts: [],
      validators: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/invoice_formats' + (self.form.id ? `/${self.form.id}/edit` : '/create') + (this.$route.query.duplicate ? '?duplicate=true' : ''))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.invoice_format ? data.invoice_format : { id: 0 }
      this.lists = data._lists || {}

      this.setAvailableMonths('invoice_format', this.$route.query.duplicate)

      // TODO: Use function ?
      if (this.$route.query.duplicate) {
        this.setDuplicate(this.available_months, true)
        if (this.form.output_id === 'imp') {
          this.form.printed_invoice_format_marker_id = this.form.invoice_format_marker_id
          if (data.invoice_format_companion) this.form.digital_invoice_format_marker_id = data.invoice_format_companion.invoice_format_marker_id
        } else {
          this.form.digital_invoice_format_marker_id = this.form.invoice_format_marker_id
          if (data.invoice_format_companion) this.form.printed_invoice_format_marker_id = data.invoice_format_companion.invoice_format_marker_id
        }
        this.submit()
      } else if (this.form.id) {
        this.form.printed_invoice_format_marker_id = this.form.invoice_format_marker_id
        this.form.digital_invoice_format_marker_id = this.form.invoice_format_marker_id
      }

      this.parseView()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }
      if (!this.form.id && this.validators.main && this.validators.main.invoice_format_marker_id) {
        this.validators.main.printed_invoice_format_marker_id = this.validators.main.invoice_format_marker_id
        this.validators.main.digital_invoice_format_marker_id = this.validators.main.invoice_format_marker_id
        delete this.validators.main.invoice_format_marker_id
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'InvoiceFormat']: options })
    },

    // Helpers
    findInvoiceFormatMarker(id) {
      if (id) return this.lists.invoice_format_markers.find(item => item.id === id)
      return { name: '' }
    },
    onChangeCombination(id) {
      console.log(id, this.VALID_RECEIPT.indexOf(id), this.VALID_RECEIPT.indexOf(id) >= 0)
      this.form.payment_receipt = false
      if (this.VALID_RECEIPT.indexOf(id) >= 0) this.form.payment_receipt = true
    },

    // SUBMIT

    submit() {
      this.$v.form.$touch()
      if (this.$v.form.$error) return

      if (!this.form.id) {
        // && !this.form.duplicate) {
        this.form.invoice_format_marker_id = this.form.printed_invoice_format_marker_id + '|' + this.form.digital_invoice_format_marker_id
      }

      if (this.form.invoice_format_combination_id === this.COMBINATION_MIXED) this.form.payment_receipt = false

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/invoice_formats', self.form) // TODO: on service ?
        .then(response => {
          self.showAlert(`Invoice format ${self.form.id ? 'saved' : 'created'}.`)
          if (this.$route.query.duplicate) self.$router.replace('/admin/parameters/invoice_formats')
          else {
            self.$router.replace(`/admin/parameters/invoice_formats/${response.data.invoice_format.id}/edit`).catch(() => {})
            self.parseData(response.data)
          }
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the invoice format.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
